<script setup>
import { defineAsyncComponent, ref, onMounted, watch } from 'vue';

import 'floating-vue/dist/style.css';

// only load the approprioate map that's needed
const MapPro = defineAsyncComponent(() =>
	import('./MapPro.vue')
)
const MapFree = defineAsyncComponent(() =>
	import('./MapFree.vue')
)

// messages related things
import { messagesStore } from "@/stores/MessagesStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { settingsStore } from "@/stores/SettingsStore.js";
import { useResizeObserver } from '@vueuse/core'
import { useMapTools } from "@/Composables/MapTools.js";

import { measureToolStore }  from "@/stores/MeasureToolStore.js";
const measureToolData = measureToolStore();
import { useMeasureTool }  from "@/Composables/UseMeasureTool.js";
const measureTool = useMeasureTool();

import PureMessages from '@/PureComponents/PureMessages.vue';

import WelcomePanel from "@/MapComponentsLayers/WelcomePanel.vue";
import PureTrackMenu from "@/MapComponentsLayers/PureTrackMenu.vue";
import LayersMenu from "@/MapComponentsLayers/LayersMenu.vue";
import LabelsMenu from "@/MapComponentsLayers/LabelsMenu.vue";
import SettingsMenu from "@/MapComponentsLayers/SettingsMenu.vue";
import ModesMenu from "@/MapComponentsLayers/ModesMenu.vue";
import LoadContestsMenu from "@/MapComponentsLayers/LoadContestsMenu.vue";
import LoadGroupsMenu from "@/MapComponentsLayers/LoadGroupsMenu.vue";
import GroupPill from "@/MapComponentsLayers/GroupPill.vue";
import ContestMenu from "@/MapComponentsLayers/ContestMenu.vue";
import SoaringSpotMenu from "@/MapComponentsLayers/SoaringSpotMenu.vue";
import ThermalHeightsLegend from '@/MapComponents/ThermalHeightsLegend.vue';
import LegendPanel from '@/MapComponentsLayers/LegendPanel.vue';
import LegendSettingsMenu from '@/MapComponentsLayers/LegendSettingsMenu.vue';
import uPlot from '@/MapComponents/uPlot.vue';
import ShareMenu from "@/MapComponentsLayers/ShareMenu.vue";
import GroupMenu from "@/MapComponentsLayers/GroupMenu.vue";
import AlertsMenu from "@/MapComponentsLayers/AlertsMenu.vue";
import TasksMenu from "@/MapComponentsLayers/TasksMenu.vue";
import UserMenu from "@/MapComponentsLayers/UserMenu.vue";
import ZonesSidebarLayers from "@/MapComponentsLayers/ZonesSidebarLayers.vue";
import GroupAddPasswordDialogue from "@/MapComponentsLayers/GroupAddPasswordDialogue.vue";
import SidePanel from '@/MapComponentsLayers/SidePanel.vue';
import KeyWatcher from '@/MapComponentsLayers/KeyWatcher.vue';
import PausePill from '@/MapComponentsLayers/PausePill.vue';

import WindSelectedPanel from "@/MapComponentsLayers/WindSelectedPanel.vue";
import DownloadMenu from '@/MapComponentsLayers/DownloadMenu.vue';
import { formatTimeFromStamp, renderDistance, formatTimeFromJSDate } from '../Helpers.js';

import { useI18n } from 'vue-i18n'
const i18nLocale = useI18n();


const messages = messagesStore();
const app = appStateStore();
const settings = settingsStore();
const { goToSelected, hasFollows, goTo } = useMapTools();

import { CogIcon, XMarkIcon, MagnifyingGlassIcon, AdjustmentsVerticalIcon, ArrowUpCircleIcon, Square3Stack3DIcon, PlusIcon, InformationCircleIcon, TrophyIcon, ListBulletIcon, ArrowRightIcon, ArrowLeftIcon, UserIcon, ChevronLeftIcon, ChevronRightIcon, SparklesIcon, RocketLaunchIcon, ViewfinderCircleIcon, TagIcon, CircleStackIcon, PlayIcon } from "@heroicons/vue/24/outline";
import FastForwardIcon from '@/Icons/FastForwardIcon.vue';


/**
 * Scrolling layout info from:
 * https://codepen.io/pawelsas/pen/vdwjpj
 * Note: Must have overflow-hidden on parent objects
 **/

var leftside = ref(null);
var header = ref(null);
var footer = ref(null);
var uplotContainer = ref(null);
var hideUpgrade = ref(false);


app.version = 3;

// check for app store
app.checkForAppstore();

// set the language
i18nLocale.locale.value = settings.language;

if (app.subscribed) hideUpgrade.value=true;
if (app.user?.trial_done && app.user?.trial_days_remain>=0 && app.user?.trial_days_remain<=4) {
	hideUpgrade.value=false;
	setTimeout(() => hideUpgrade.value = true, 3000);
}
if (app.fromAppStore) hideUpgrade.value=true; //always hide from app store



// watch sizes of the panels  so we know the padding to apply to the map
useResizeObserver(uplotContainer, (entries) => {
	const entry = entries[0];
	const { width, height } = entry.contentRect;
	app.chartHeight = Math.round(height);
});
useResizeObserver(header, (entries) => {
	const entry = entries[0];
	const { width, height } = entry.contentRect;
	app.headerHeight = Math.round(height);
});
useResizeObserver(footer, (entries) => {
	//console.log(entries);
	const entry = entries[0];
	const { width, height } = entry.contentRect;
	app.footerHeight = Math.round(height);
});
useResizeObserver(leftside, (entries) => {
	const entry = entries[0];
	const { width, height } = entry.contentRect;
	app.leftWidth = Math.round(width);
	app.leftHeight = Math.round(height);
});

function getDarkMode() {
	if (settings.darkMode==null) {
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) return 'dark';
	}
	return settings.darkMode;
}

const modal = ref(null);
watch(() => app.show, (currentValue, oldValue) => {
	if (currentValue!==null) {
		modal.value.focus();
	}

	// reset the history menu if we have exited the menu
	if (currentValue==null) {
		app.showBack = [];
	}
});

onMounted(() => {
	app.windowWidth = window.innerWidth; // set initial size
	window.addEventListener('resize', () => {
		app.windowWidth = window.innerWidth;
		app.windowHeight = window.innerHeight;
	})
});

function toggleFollow() {
	settings.pauseFollowing=!settings.pauseFollowing; 
	if (!settings.pauseFollowing) goToSelected();
}


</script>


<style>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>




<template>
	<div :class="getDarkMode()">
		<div v-if="app.user && app.user.subscribed">
			<map-pro class="z-0"></map-pro>
		</div>
		<div v-if="!app.user || !app.user.subscribed">
			<map-free class="z-0"></map-free>
		</div>


		<div v-if="app.show!==null" class="absolute w-full h-full" @click="app.show=null;"></div>

		<KeyWatcher></KeyWatcher>

		<div v-if="!settings.showWelcomeScreen" 
			class="flex flex-col absolute w-full h-full pointer-events-none">

			<!-- Header -->
			<div ref="header" class="flex items-center justify-between gap-2 sm:gap-2  mt-2 mx-2" v-show="app.show==null">

				<div class="flex self-start gap-1">

					<button class="z-10 round-button bg-center"  title="PureTrack"
						@click="app.show='puretrack-menu'; "
						style="background-image: url(/images/puretrack-logo.png); background-size: 50%; background-repeat: no-repeat;">
						<div class="rounded-full w-[24px] h-[24px]"></div>
					</button>

					<button class=" menu-row-clickable ui-pill ui-pill-normal px-2 py-1 self-center" @click="app.showBack=null; app.show='modes-menu'">
						<div class=" flex gap-1 content-center" v-if="settings.mode=='gliding'">
							<img src="/images/icons/glider_w.svg" class="w-6 h-6 min-w-6">
							<span class="hidden sm:inline pr-1">{{ $t('welcome.gliding') }}</span>
						</div>
						<div class="self-center flex gap-1 content-center" v-if="settings.mode=='freeflight'">
							<img src="/images/icons/paraglider_w.svg" class="w-6 h-6 min-w-6">
							<span class="hidden sm:inline pr-1">{{ $t('welcome.freeflight') }}</span>
						</div>
						<div class="self-center flex gap-1 content-center" v-if="settings.mode=='marine'">
							<img src="/images/icons/sailboat_w.svg" class="w-6 h-6 min-w-6">
							<span class="hidden sm:inline pr-1">{{ $t('welcome.marine') }}</span>
						</div>
						<div class="self-center flex gap-1 content-center" v-if="settings.mode=='ga'">
							<img src="/images/icons/plane_w.svg" class="w-6 h-6 min-w-6">
							<span class="hidden sm:inline pr-1">{{ $t('welcome.general-aviation') }}</span>
						</div>
						<div class="self-center flex gap-1 content-center" v-if="settings.mode=='all'">
							<img src="/images/icons/jet_w.svg" class="w-6 h-6 min-w-6">
							<span class="hidden sm:inline pr-1">{{ $t('welcome.all') }}</span>
						</div>
					</button>

				</div>


				<div class="flex flex-wrap flex-col gap-2 items-center w-fit">

					<transition>
						<a v-if="!hideUpgrade && !app.hoverPoint" href="/upgrade" :title="$t('app.upgrade')"  class=" ui-pill  pl-3 pr-1 py-1 self-center flex items-center gap-2 bg-puretrack text-white text-sm">
							<RocketLaunchIcon class="w-5 h-5 text-white " />

							<div v-if="!app.user?.trial_done">
								<span class="whitespace-nowrap hidden sm:inline">{{$t('app.try-puretrack-pro')}}</span>
								<span class="whitespace-nowrap sm:hidden inline">{{$t('app.try-pro')}}</span>
							</div>

							<div v-if="app.user?.trial_done">
								<span v-if=" app.user.trial_days_remain<=0">
									{{ $t('app.upgrade') }}
								</span>

									<span v-if="app.user.trial_days_remain>1">
										<span class="font-bold">
										{{app.user.trial_days_remain}}</span> {{$t('app.days-remain')}}
									</span>
									<span v-if="app.user.trial_days_remain<=1 && app.user.trial_days_remain>0">
										{{$t('app.1-day-remain')}}
									</span>

							</div>

							<button class="pill-button" @click.prevent="hideUpgrade=true">
								<XMarkIcon class="size-4 text-[#28b7c8]" />
							</button>

						</a>
					</transition>


					<PausePill :seconds="false"></PausePill>

					<div v-if="measureToolData.measureDistance" class="flex items-center gap-2 ui-pill pl-3 py-1 pr-1 bg-red-800 text-white text-sm w-fit">

						<div>
							{{renderDistance(measureToolData.measureDistance, settings.distanceUnits)}}
						</div>

						<button :title="$t('app.close')" 
							class="pill-button flex items-center justify-center p-1">
							<XMarkIcon class="size-4 text-red-800" @click="measureTool.clearMeasure();" />
						</button>

					</div>
				</div>

				<div class="flex gap-1 sm:gap-2 self-start">

					<button :title="$t('settings-menu.settings')" class="round-button" @click="app.show='settings-menu'">
						<CogIcon class="size-6" />
					</button>


					<button :title="$t('layers-menu.layers')" class="round-button" @click="app.show='layers-menu'">
						<Square3Stack3DIcon class="size-6" />
					</button>


					<button :title="$t('user.user')" class="round-button" @click="app.show='user-menu'">
						<UserIcon class="size-6" />
					</button>
				</div>

			</div>

			<!-- Middle row -->
			<div class="flex overflow-hidden h-full">


				<div  ref="leftside" class="flex flex-col w-full sm:w-fit overflow-hidden justify-end gap-2 z-10 sm:pr-8 sm:pb-2 sm:pl-2 pt-2">
					<side-panel />
				</div>


				<!-- middle --> 
				<div ref="middle" class="flex flex-col overflow-hidden flex-grow justify-end">
					
				</div>

				<!-- right --> 

				<div ref="right" class="flex flex-col mt-[165px] mr-1 absolute right-2 z-0"  v-if="settings.showThermalHeights" v-show="app.show==null">
					<div class="flex flex-col overflow-y-scroll">
						<ThermalHeightsLegend></ThermalHeightsLegend>
					</div>
				</div>

			</div>


			<!-- Footer -->
			<div ref="footer" class="flex flex-col " v-show="app.show===null ||  app.show=='legend-settings' || app.show=='download-menu'">

				<WindSelectedPanel v-if="app.showLegend=='wind'" />

				<div class=" sm:px-2 sm:pb-2 " v-if="settings.showChart && app.selected">
					<div class="" ref="uplotContainer ">

						<uPlot class="w-full"></uPlot>

					</div>
				</div>

				<div v-if="!settings.showChart && app.selected"
				@click="settings.showChart=true"
				class="bg-gray-900 mx-2 mb-2 rounded text-white text-xs p-1 w-fit absolute right-0 bottom-0 z-50 cursor-pointer pointer-events-auto border">
					Show Chart
				</div>

			</div>

		</div>

		<div class="flex content-center absolute w-full h-full pointer-events-none z-50"  ref="modal" @keyup.esc="app.show=null;" tabindex="-1">
			<WelcomePanel v-if="settings.showWelcomeScreen"></WelcomePanel>
			<PureTrackMenu v-if="app.show=='puretrack-menu'"></PureTrackMenu>
			<LayersMenu v-if="app.show=='layers-menu'"></LayersMenu>
			<LabelsMenu v-if="app.show=='labels-menu'"></LabelsMenu>
			<SettingsMenu v-if="app.show=='settings-menu'"></SettingsMenu>
			<ModesMenu v-if="app.show=='modes-menu'"></ModesMenu>
			<LoadGroupsMenu v-if="app.show=='load-groups-menu'"></LoadGroupsMenu>
			<LoadContestsMenu v-if="app.show=='load-contests-menu'"></LoadContestsMenu>
			<ContestMenu v-if="app.show=='contest-menu'"></ContestMenu>
			<LegendSettingsMenu v-if="app.show=='legend-settings'"></LegendSettingsMenu>
			<SoaringSpotMenu v-if="app.show=='soaringspot-menu'"></SoaringSpotMenu>
			<ShareMenu v-if="app.show=='share-menu'"></ShareMenu>
			<TasksMenu v-if="app.show=='tasks-menu'"></TasksMenu>
			<GroupMenu v-if="app.show=='group-menu'"></GroupMenu>
			<AlertsMenu v-if="app.show=='alerts-menu'"></AlertsMenu>
			<UserMenu v-if="app.show=='user-menu'"></UserMenu>
			<DownloadMenu v-if="app.show=='download-menu'"></DownloadMenu>
			<GroupAddPasswordDialogue v-if="app.show=='group-password'"></GroupAddPasswordDialogue>

		</div>

		<pure-messages class="z-50"></pure-messages>

	</div>
</template>
