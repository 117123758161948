<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed, nextTick } from 'vue';
import dayjs from 'dayjs';

import { settingsStore } from "@/stores/SettingsStore.js";
import { appStateStore } from "@/stores/AppStateStore.js";
import { pointsStore } from "@/stores/PointsStore.js";
import { messagesStore } from "@/stores/MessagesStore.js";
import { pointTypesStore } from "@/stores/PointTypesStore.js";
import { useMapTools } from "@/Composables/MapTools.js";
import { useContestManager } from "@/Composables/UseContestManager.js";
import scrollIntoView from 'scroll-into-view-if-needed'
import { sourcesStore } from "@/stores/SourcesStore.js";
import AircraftDetails from '@/MapComponentsLayers/AircraftDetails.vue';
import DownloadIcon from '@/Icons/DownloadIcon.vue';
import PausePill from '@/MapComponentsLayers/PausePill.vue';

import { useGroups } from "@/Composables/UseGroups.js";
const groups = useGroups();

import { tasksStore } from "@/stores/TasksStore.js";
const tasks = tasksStore();

const points  = pointsStore(); 
const app = appStateStore();
const settings = settingsStore();
const pointTypes = pointTypesStore();
const mapTools = useMapTools();
const messages = messagesStore();
const contestManager = useContestManager();
const sources  = sourcesStore();

const sourceCounts = ref([]);
const sourceCountsLoading = ref(0);

const props = defineProps(['infooter']);
import interact from 'interactjs'

const selectedpanel = ref();
const uplotContainer = ref();
const heightGuide = ref();
const previousHeight = ref(500);


import SelectedTabTrips from '@/MapComponentsLayers/SelectedTabTrips.vue';

import { pointShortName, renderHeight, renderSpeed, copyToClipboard, renderTimeAgo, renderTimeAgoFromUnix, formatDateTimeFromStamp, renderAlertDescription, renderUsername, formatDateTimeFromStampWithTZ, timezoneDifference, formatTimeFromStamp, limitStringEnd, apiDateToJS, renderName, renderDistance, formatStartTime, formatShortTimeWithTZ, roundDecimals, formatTimeFromJSDate } from '../Helpers.js';

import { XMarkIcon, Bars3Icon, MapPinIcon, ChevronUpIcon, ArrowUpOnSquareIcon, ViewfinderCircleIcon, PresentationChartLineIcon, PhotoIcon, LockClosedIcon, TrophyIcon, ArrowsUpDownIcon, ArrowRightIcon, ArrowUpIcon, ArrowDownIcon, BellAlertIcon, ArrowPathIcon, DocumentDuplicateIcon, CheckIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon, CogIcon, EllipsisHorizontalIcon, ChevronLeftIcon, InformationCircleIcon, UserGroupIcon, UserPlusIcon, UserMinusIcon, PencilIcon, ChevronRightIcon, ChevronDownIcon, PlayIcon, LinkIcon, BugAntIcon, ClockIcon, ArrowRightEndOnRectangleIcon, PaperAirplaneIcon, RocketLaunchIcon } from "@heroicons/vue/24/outline";
import FastForwardIcon from '@/Icons/FastForwardIcon.vue';

import { useResizeObserver } from '@vueuse/core'
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.setDefaultLocale(en.locale)
TimeAgo.addLocale(en);

const timeAgo = new TimeAgo('en');

const showTypeName = ref(false);
const showTrackerID = ref(false);
const copied = ref(false);
const showTimeAgo = ref(true);
const showThermalInfo = ref(true);
const showBasicStats = ref(false);
const showActualTime = ref(false);
const showDebug = ref(false);
const showLocation = ref(false);
const showNameInfo = ref(false);
const showContestInfo = ref(true);


const selectedAircraftDetails = ref(null);
const loadingAircraftDetails = ref(false);
const loadingTargetDetails = ref(false);


const copiedLink = ref(false);
const copiedGoogleLink = ref(false);
const copiedAppleLink = ref(false);

const taskStats = ref({});


function copy(target) {
	copyToClipboard(calcUrl()); 
	messages.success('Link copied to clipboard');
	copiedLink.value = true;
	setTimeout(function (target) {
		copiedLink.value = false;
	}, 2000, target)
}
function copyGoogle(url) {
	copyToClipboard(url); 
	messages.success('Google link copied to clipboard');
	copiedGoogleLink.value = true;
	setTimeout(function () {
		copiedGoogleLink.value = false;
	}, 2000)
}
function copyApple(url) {
	copyToClipboard(url); 
	messages.success('Apple link copied to clipboard');
	copiedAppleLink.value = true;
	setTimeout(function () {
		copiedAppleLink.value = false;
	}, 2000)
}

function canShare() {
	return navigator.share;
}
function clickShare() {
	if (canShare()) {
		navigator.share({
			title: 'puretrack.io',
			text: 'Shared from PureTrack.io',
			url: calcUrl(),
		})
		.then(() => console.log('Successful share'))
		.catch((error) => console.log('Error sharing', error));
	}
}


function calcUrlParams() {

	var string = "";
	var params = []; // array to put params into
	if (app.selected!==null) {

		if (app.getHashTag()) {
			return app.getHashTag();
		}

		if (points.selected.rego) {
			params.push('a=' + points.selected.rego);
		} else {
			params.push('k=' + app.selected);
		}
	}
	for (var i=0; i<params.length; i++) {
		if (i==0) string += "?" + params[i];
		else string += "&" + params[i];
	}

	return string;
}

function calcUrl(withProtocol = true) {
	var string = "";

	if (withProtocol) string = window.location.protocol + "//";
	string = string + window.location.host + '/';

	string = string + calcUrlParams();

	return string;
}

function googleLink() {
	return 'https://www.google.com/maps/place/' + points.selected.lat + '+' + points.selected.long + '/';
}

function appleLink() {
	return 'http://maps.apple.com/?q=' + points.selected.lat + ',' + points.selected.long;
}


const point = computed(() => {
	// console.log('point computed');
	// console.log(app.hoveringOnChart);
	// console.log(app.hoverPoint);
	// console.log(points.selected);
	if (app.hoveringOnChart && app.hoverPoint) return app.hoverPoint;
	return points.selected;
});


function toggleChart() {
	// setChartHeight();

	// check if we should make the panel bigger or smaller
	// if (settings.showChart) {
	// 	setSize(selectedpanel.value.getBoundingClientRect().height - settings.altitudeChartSize);
	// } else {
	// 	setSize(selectedpanel.value.getBoundingClientRect().height + settings.altitudeChartSize);
	// }

	settings.showChart=!settings.showChart;
	// if (settings.showChart) {
	// 	nextTick(() => {
	// 		setChartHeight(); 
	// 		setChartWidth();
	// 	});
	// }
	
	
}

function clickIDcopy(target) {
	copyToClipboard(target); 
	messages.success('ID copied to clipboard');
	copied.value = true;
	setTimeout(function (target) {
		copied.value = false;
	}, 2000, target)
}


watch(() => app.selected, (currentValue, oldValue) => {
	sourceCounts.value.splice(0);
	climbs.value.splice(0);
	loadStuff();
	taskStats.value = points.getTaskStats(app.selected);

});

watch(() => app.compStats, (currentValue, oldValue) => {
	// get the latest stats
	taskStats.value = points.getTaskStats(app.selected);
});



function getSourceCounts() {
	if (!app.selected) return;

	//console.log('loading counts');
	sourceCountsLoading.value++;

	window.axios.get('/api/trails/' + app.selected + '/counts').then(function (response) {
		sourceCounts.value = response.data.data;
		sourceCountsLoading.value--;
	}).catch(error => {
		console.log(error);
		messages.error("Aircraft not found");
		sourceCountsLoading.value--;
	});
}

// load the source counts
function loadStuff() {
	if (settings.showSource) getSourceCounts();
	if (showClimbs.value) getClimbs();
}



onMounted(() => {
	if (!app.selected) {
		settings.sideBarPage='home';
	}

	// start the timer and load stuff
	loadStuff();
	setInterval(loadStuff(), 10000);
});



function renderMinutes(seconds)
{
	let minutes = Math.round(seconds / 60);
	return minutes + 'min';
}

function calcClimbRate(climb) {
	let height = climb.max_alt - climb.start_alt;
	let seconds = Math.round((apiDateToJS(climb.max_ts) - apiDateToJS(climb.start_ts)) / 1000);
	return height/seconds;
}

function renderClimbRate(climb) {
	let rate = calcClimbRate(climb);
	return renderSpeed(rate, settings.verticalUnits, 1);
}

function averageClimbs() {
	let total=0;
	for (let i=0; i<climbs.value.length; i++) {
		total = total + calcClimbRate(climbs.value[i]);
	}
	//console.log('average total is ' + total);
	return total/climbs.value.length;
}


const climbs = ref([]);
const climbsLoading = ref(0);
const showClimbs = ref(false);
const averageClimbRate = ref(null);

function getClimbs() {
	if (!app.selected) return;
	if (!app.subscribed) return;
	averageClimbRate.value = null;

	// console.log('loading climbs');
	climbsLoading.value++;

	window.axios.get('/api/climbs/' + app.selected).then(function (response) {
		//console.log(response);
		climbs.value = response.data.data;
		climbsLoading.value--;
		averageClimbRate.value = averageClimbs();

		for (let i=0; i<climbs.value.length; i++) {
			let climb = climbs.value[i];
			let start_ts = dayjs.utc(climb.start_ts).unix();
			let id_ts = String(start_ts).substr(5);
			climbs.value[i].id = climb.k + id_ts;
		}

	}).catch(error => {
		console.log(error);
		messages.error("Climbs not loaded");
		climbsLoading.value--;
	});
}

function climbingType(point) {
	switch (point.object_type)
	{
		case 1: // glider
		case 2: // tow plane
		case 6: // hang glider
		case 7: // paraglider
		case 17: // paramotor
			return true;
			break;
	}
	return false;
}
function clickClimb(climb) {

	// create a climb object
	let newClimb = {};
	newClimb.age = climb.time_taken;
	newClimb.climb = climb.climb;
	newClimb.climbRate = climb.rate;
	newClimb.climbRateRaw = climb.rate;
	newClimb.iconSize = .3;
	newClimb.id = climb.id;
	newClimb.image = 2;
	newClimb.key = climb.k;
	newClimb.max_alt = climb.max_alt;
	newClimb.max_ts = dayjs.utc(climb.max_ts).unix();
	newClimb.start_alt = climb.start_alt;
	newClimb.start_ts = dayjs.utc(climb.start_ts).unix();

	// console.log(app.selectedThermal.id); 
	// console.log(newClimb.id);
	// console.log(newClimb);

	app.selectedThermal = newClimb;
}
function isSelectedThermal(climb) {
	if (!app.selectedThermal) return false;
	// console.log('comparing ' + app.selectedThermal.id + ' vs ' +  climb.id);
	return app.selectedThermal.id == climb.id;
}
</script>


<template><div>



	<div v-if="!point" class="p-4 text-gray-500 text-center">

		{{ $t('selected.nothing-selected') }}

	</div>

	<div ref="selectedpanel" v-if="point" >

<!-----------  Selected Name  ---------- -->

		<div class="flex flex-col gap-2 border-b border-b-gray-300 dark:border-b-black pb-1">
			
			<div class="flex gap-2 w-full items-center pt-1 pl-2 pr-2">

				<button 
					v-on:click="showNameInfo = !showNameInfo"
					class="text-sm text-left flex gap-1 items-center flex-grow">
					<ChevronRightIcon v-if="!showNameInfo" class="size-4"></ChevronRightIcon>
					<ChevronDownIcon v-if="showNameInfo" class="size-4"></ChevronDownIcon>

					<div v-if="point"  class="flex  items-center gap-1 flex-grow  overflow-clip">
						<div class="size-6 min-w-6 rounded-full" :style="{backgroundColor: '#' + point.colour}">
							<img :src="pointTypes.getIconImage(point.object_type)" width=32 height=32 :style="{ transform: 'rotate('+ point.course+'deg)'}">
						</div>
						<h3 class="font-bold text-xl whitespace-nowrap overflow-clip overflow-ellipsis flex-shrink">{{pointShortName(point, 20, settings.shortenAircraftRegistrations)}}</h3>
					</div>

				</button>


				<div v-if="!point" class="flex-grow items-center justify-center">

					<Transition> 
						<LoadingHorizontal class="mx-auto " />
					</Transition>
				</div>

				<button
					class="flex gap-1 items-center max-w-[100px]  whitespace-nowrap hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer p-1 rounded-md" @click="copy()">
					<LinkIcon class="size-5 min-w-5" v-show="!copiedLink" />
					<CheckIcon class="size-5 min-w-5 text-green-600" v-show="copiedLink" />
					<div class="text-sm">{{ $t('selected.copy-link') }}</div>
				</button>
			</div>

			<div class="mx-2 gap-2 overflow-clip overflow-ellipsis whitespace-nowrap text-sm max-w-[340px]" 
				v-if="showNameInfo">

				<a  :href="calcUrl()" class="link">{{ calcUrl() }}</a>
			</div>

		</div>


		<div class="flex flex-grow overflow-y-scroll w-full border-t border-t-white dark:border-t-gray-700 pb-6" >

			<div class="flex w-full flex-shrink-0">

				<div class="flex flex-col flex-grow w-full pt-2 gap-2 mr-2">





<!-----------  Time  ---------- -->
	
	<div v-if="point" class="ml-2 flex flex-wrap items-center gap-2">

		<div class="flex-grow">
			<div class="flex  items-center gap-1 cursor-pointer" @click="showActualTime = !showActualTime">

				<ChevronRightIcon v-if="!showActualTime" class="size-4"></ChevronRightIcon>
				<ChevronDownIcon v-if="showActualTime" class="size-4"></ChevronDownIcon>

				<ClockIcon class="size-4" />
				<span class="" v-show="!app.hoveringOnChart">Last Seen</span>
				<span class="" v-show="app.hoveringOnChart">Viewing</span>
				<span class="font-bold">{{ renderTimeAgo(point.jsdate, app.currentTime) }}</span> ago
			</div>
		</div>

		<PausePill :seconds="false"></PausePill>

		<div v-if="!app.hoverPoint">
			{{ formatTimeFromStamp(point.stamp, false, false, point.timezone, settings.timeUnits, settings.timezoneUnits) }}
		</div>

	</div>


	<div v-if="showActualTime" class=" text-sm text-gray-600 dark:text-gray-400 ml-2">
		<div>{{ formatDateTimeFromStamp(point.stamp) }} Your Time</div>
		<div v-if="point.timezone" class="flex flex-wrap">
			<div class="mr-1">{{formatDateTimeFromStampWithTZ(point.stamp, point.timezone)}}</div>
			<div class="mr-1">{{point.timezone}}</div>
			<div>{{timezoneDifference(point.stamp, point.timezone)}}</div>
		</div>
		<div>
			{{formatDateTimeFromStampWithTZ(point.stamp, "UTC")}} UTC
		</div>
	</div>



<!-----------  Contest Details  ---------- -->

	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300" v-if="app.comp ">
	
	<div class="flex flex-col ml-2 gap-2" v-if="app.comp">

		<button 
			v-on:click="showContestInfo = !showContestInfo"
			class="text-left flex gap-1 items-center flex-wrap">
			<ChevronRightIcon v-if="!showContestInfo" class="size-4 flex-grow-0"></ChevronRightIcon>
			<ChevronDownIcon v-if="showContestInfo" class="size-4 flex-grow-0"></ChevronDownIcon>

			<TrophyIcon class="size-4" />

			<div class='text-sm mr-2'>{{ $t('selected.task') }}</div>
		
			<div class="flex gap-4 justify-end items-center flex-grow">
				

				<div v-if="point.comp_name">
					{{ renderName(point.comp_name) }}
				</div>

				<div v-if="point.comp_class">
					{{ renderName(point.comp_class) }}
				</div>
			</div>

		</button>

		<div class="flex flex-wrap gap-2 items-center" v-if="showContestInfo">
			
			<div v-if="!taskStats?.legs?.turnpoints" class="text-center text-gray-600 dark:text-gray-400 text-sm">
				{{ $t('tasks.not-started') }}
			</div>


			<table class="w-full mx-2"  v-if="taskStats?.legs?.turnpoints">
				<tr class="font-bold">
					<td colspan="2">
						Total
					</td>
					<td class="text-sm text-left">
						{{renderDistance(taskStats.legs.turnpoints[taskStats.legs.turnpoints.length-1].distance_cumulative, settings.distanceUnits)}} 
					</td>
					<td class="text-sm text-center">
						{{renderMinutes(taskStats.legs.turnpoints[taskStats.legs.turnpoints.length-1].seconds_from_start, settings.distanceUnits)}} 
					</td>
					<td></td>
					<td class="text-sm text-right">
						{{ renderSpeed(taskStats.legs.turnpoints[taskStats.legs.turnpoints.length-1].distance_cumulative / taskStats.legs.turnpoints[taskStats.legs.turnpoints.length-1].seconds_from_start, settings.speedUnits) }}
					</td>
				</tr>

				<template v-if="taskStats?.legs?.turnpoints" >
					<tr v-for="leg in taskStats.legs.turnpoints" :key="leg.id">
						<td class="text-sm text-gray-600 dark:text-gray-400 text-center flex items-center gap-1 flex-grow-0">

							<CheckIcon v-show="leg.type!='inprogress'" class="bg-green-600 dark:bg-green-500 rounded-full text-white p-0.5 flex-grow-0 size-4"></CheckIcon>

							<ArrowRightIcon v-show="leg.type=='inprogress'" class="bg-gray-400 dark:bg-gray-600 rounded-full text-white p-0.5 flex-grow-0 size-4"></ArrowRightIcon>

							<div v-show="leg.order==0">S</div>
							<div v-show="leg.order>0 && leg.type!='finish'">{{leg.order}}</div>
							<div v-show="leg.type=='finish'">F</div>
						</td>
						<td v-if="leg.task_turnpoint?.turnpoint?.name" class="text-sm overflow-clip max-w-[120px]">
							<div class="w-full overflow-clip overflow-ellipsis whitespace-nowrap">{{leg.task_turnpoint.turnpoint.name}}</div>
						</td>
						<td class="text-sm">
							<div v-if="leg.distance_from_previous">
								{{renderDistance(leg.distance_from_previous, settings.distanceUnits)}}
							</div>
						</td>
						<td class="text-sm text-center">
							<div v-if="leg.seconds_from_previous">
								{{renderMinutes(leg.seconds_from_previous)}}
							</div>
							<div v-show="leg.order==0">

							</div>
						</td>
						<td class="text-sm text-center">
							{{ formatStartTime(apiDateToJS(leg.ts), point.timezone) }}
						</td>
						<td class="text-sm text-right">
							<div v-if="leg.distance_from_previous && leg.seconds_from_previous">
								{{ renderSpeed(leg.distance_from_previous / leg.seconds_from_previous, settings.speedUnits) }}
							</div>
						</td>
					</tr>
				</template>
			</table>
		</div>

	</div>

<!-----------  Thermal Details  ---------- -->

	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300" v-if="app.selectedThermal ">
	
	<div class="flex flex-col ml-2 gap-2" v-if="app.selectedThermal">


		<div v-if="point" class="flex flex-wrap items-center">
			<button v-if="!settings.showThermalInfo" 
				v-on:click="settings.showThermalInfo = !settings.showThermalInfo"
				class="text-sm text-left flex gap-1 items-center flex-grow">
				<ChevronRightIcon class="size-4"></ChevronRightIcon>
				<img src="/images/icons/spiral7.png" class="size-6">
				Thermal Details
			</button>
			<button v-if="settings.showThermalInfo" 
				v-on:click="settings.showThermalInfo = !settings.showThermalInfo"
				class="text-sm text-left flex gap-1 items-center flex-grow">
				<ChevronDownIcon class="size-4"></ChevronDownIcon>
				<img src="/images/icons/spiral7.png" class="size-6">
				Thermal Details
			</button>

			<div class="">
				{{ renderTimeAgoFromUnix(app.selectedThermal.max_ts, app.currentTime) }} ago
			</div>

		</div>

		<div class="flex flex-wrap gap-2 items-center" v-if="settings.showThermalInfo">
			<div v-if="app.selectedThermal" class="whitespace-nowrap flex flex-1 gap-2 items-center">
				<span class="infobox-label flex-1">Total Climb</span>
				<div class="flex-1 whitespace-nowrap">{{renderHeight(app.selectedThermal.max_alt - app.selectedThermal.start_alt, settings.altitudeUnits,0 )}}</div>
			</div>
			<div v-if="app.selectedThermal" class="whitespace-nowrap flex flex-1 gap-2 items-center">
				<span class="infobox-label flex-1">Rate</span>
				<div class="flex-1 whitespace-nowrap">{{ renderSpeed(app.selectedThermal.climbRateRaw, settings.verticalUnits, 1) }}</div>
			</div>
		</div>

		<div class="flex flex-wrap gap-2 items-center" v-if="settings.showThermalInfo">
			<div v-if="app.selectedThermal" class="whitespace-nowrap flex flex-1 gap-2 items-center">
				<span class="infobox-label flex-1">From</span> 
				<div class="flex-1 whitespace-nowrap">{{renderHeight(app.selectedThermal.start_alt, settings.altitudeUnits,0 )}}</div>
			</div>
			<div v-if="app.selectedThermal" class="whitespace-nowrap flex flex-1 gap-2 items-center">
				<span class="infobox-label flex-1">to</span>
				<div class="flex-1 whitespace-nowrap">{{renderHeight(app.selectedThermal.max_alt, settings.altitudeUnits,0 )}}</div>
			</div>
		</div>

	</div>



<!-----------  Basic Stats  ---------- -->
	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300">

	<div class="flex flex-col ml-2 gap-2" v-if="point">

		<div class="flex flex-wrap gap-4">

			<button 
				v-on:click="showBasicStats = !showBasicStats"
				class="text-sm text-left flex gap-1 items-center flex-grow">
				<ChevronRightIcon v-if="!showBasicStats" class="size-4"></ChevronRightIcon>
				<ChevronDownIcon v-if="showBasicStats" class="size-4"></ChevronDownIcon>
				<InformationCircleIcon class="size-4" />
				
				{{ $t('selected.metrics') }}

			</button>

			<div v-if="!isNaN(point?.speed)" class="whitespace-nowrap flex gap-2 items-center">
				<div class="flex gap-1 items-center">
					<ArrowRightIcon class="w-4 h-4" /> 
					<span>{{renderSpeed(point.speed, settings.speedUnits, point.speed>10 ? 0:1 )}}</span>
				</div>
			</div>

			<div v-if="!isNaN(point.alt)" class="">
				<div class="flex gap-2 items-baseline" v-if="settings.showAboveGroundLevel==0 || settings.showAboveGroundLevel==2">
					<div class="infobox-label ">Alt</div>
					<div class="">{{renderHeight(point.alt, settings.altitudeUnits)}}</div>
				</div>
			</div>

		</div>



		<div class="flex flex-wrap gap-2 items-center" v-if="showBasicStats && (point.name || point.club)">
			<div v-if="point?.name" class="whitespace-nowrap flex gap-2 items-center flex-1">
				<div class="infobox-label flex-1">Name</div>
				<div class="flex-1">{{point.name}}</div>
			</div>
			<div v-if="point?.model" class="whitespace-nowrap flex gap-2 items-center flex-1">
				<div class="infobox-label flex-1">Club</div>
				<div class="flex-1">{{point.club}}</div>
			</div>
		</div>


		<div class="flex flex-wrap gap-2 items-center" v-if="showBasicStats && (point.model || point.handicap)">
			<div v-if="point?.model" class="whitespace-nowrap flex gap-2 items-center flex-1">
				<div class="infobox-label flex-1">Aircraft</div>
				<div class="flex-1">{{point.model}}</div>
			</div>
			<div v-if="point?.handicap" class="whitespace-nowrap flex gap-2 items-center flex-1">
				<div class="infobox-label flex-1">Handicap</div>
				<div class="flex-1">{{point.handicap}}</div>
			</div>
		</div>


		<div class="flex flex-wrap gap-2" v-if="showBasicStats">

			<div class="whitespace-nowrap flex  gap-2 flex-1 items-center">
				<div class="infobox-label flex-1">Vertical</div>
				<div class="flex gap-1 flex-1 items-center" v-if="!isNaN(point?.vspeed)">
					<ArrowUpIcon v-if="point.vspeed>=0" class="w-4 h-4" />
					<ArrowDownIcon v-if="point.vspeed<0" class="w-4 h-4" />
					{{renderSpeed(point.vspeed, settings.verticalUnits, 1)}}
				</div>
				<div v-if="isNaN(point?.vspeed)" class="flex gap-1 flex-1"></div>
			</div>

			<div  class="whitespace-nowrap flex flex-1 gap-2 items-center">
				<div class="infobox-label flex-1">Climb Rate</div>
				<div class="flex-1 flex gap-1" v-if="app.subscribed && point.thermal_climb_rate">
					<ArrowPathIcon class="w-4 h-4 mr-1" />
					{{renderSpeed(point.thermal_climb_rate, settings.verticalUnits, 1)}}
				</div>
				<div class="flex-1" v-if="!app.subscribed">
					<a href="/upgrade"><LockClosedIcon class="h-4 w-4" /></a>
				</div>
			</div>

		</div>

		<div class="flex flex-wrap gap-2" v-if="showBasicStats">

			<div v-if="point?.gl" class=" flex-1 ">
				<div class="flex gap-2" v-if="point?.gl && !isNaN(point?.alt)">
					<div class="infobox-label flex-1">AGL</div>
					<div class="flex-1">{{renderHeight(point.alt - point.gl, settings.altitudeUnits)}}</div>
				</div>
			</div>
			<div v-if="!point?.gl" class=" flex-1 ">
				<div class="flex gap-2" v-if="!point.gl && !isNaN(point?.alt)">
					<div class="infobox-label flex-1">AGL</div>
					<div class="flex-1">{{renderHeight(point.alt, settings.altitudeUnits)}}</div>
				</div>
			</div>

			<div class="whitespace-nowrap flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">
					{{ $t('selected.ground-level') }}
				</div>
				<div class="flex-1 whitespace-nowrap" v-if="point?.gl" >
					{{renderHeight(point.gl, settings.altitudeUnits)}}
				</div>
			</div>


		</div>


		<div v-if="showBasicStats && point?.username" class="whitespace-nowrap flex flex-1 gap-2 items-center">
			<div class="flex flex-1 gap-2">
				<div class="infobox-label flex-1">
					{{ $t('selected.user') }}
				</div>
				<div class="flex-1" v-html="renderUsername(point)"></div>
			</div>
			<div class="flex-1"></div>
		</div>

		<div v-if="showBasicStats && point?.phone" class="whitespace-nowrap flex flex-1 gap-2 items-center">
			<div  class="whitespace-nowrap flex gap-2 items-center flex-1">
				<div class="infobox-label flex-1">Ph</div>
				<div class="flex-1" v-if="point?.phone">{{point.phone}}</div>
			</div>
			<div class="flex-1"></div>
		</div>


	</div>


<!-----------  Trips  ---------- -->

<SelectedTabTrips :timezone="point.timezone"></SelectedTabTrips>


<!-----------  Climbs  ---------- -->
	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300" v-if="climbingType(point)">

	<div class="ml-2 flex flex-col " v-if="climbingType(point)">

		<div class="flex-grow">
			<div class="flex items-center gap-1 cursor-pointer text-sm" :class="[app.subscribed ? '':'opacity-50']" @click="showClimbs = !showClimbs; loadStuff();">

				<ChevronRightIcon v-if="!showClimbs" class="size-4 flex-shrink-0"></ChevronRightIcon>
				<ChevronDownIcon v-if="showClimbs" class="size-4 flex-shrink-0"></ChevronDownIcon>

				<img src="/images/icons/spiral7.png" class="size-6">
				<div class="whitespace-nowrap">{{ $t('climbs.recent-climbs') }}</div>
				<LoadingHorizontal  v-if="app.subscribed && climbsLoading" />

				<div class="flex items-center gap-2 w-full justify-end">

					<div class="flex flex-grow justify-end" v-if="!app.subscribed">
						<a href="/upgrade" class="flex items-center gap-2">
							<LockClosedIcon class="h-4 w-4" /> {{ $t('app.upgrade') }}
						</a>
					</div>

					<div v-if="app.subscribed && averageClimbRate" class="text-sm ">
						{{ $t('climbs.average') }}
						{{ renderSpeed(averageClimbRate, settings.verticalUnits, 1) }}
					</div>
				</div>

			</div>
		</div>


		<div class="flex text-sm text-gray-600 dark:text-gray-400" v-if="app.subscribed && showClimbs && climbs.length==0">
			{{ $t('climbs.no-climbs-yet') }}
		</div>


		<div class="flex" v-if="app.subscribed && showClimbs && climbs.length>0">
			<table class="w-full">
				<tr>
					<th></th>
					<th class="text-sm text-gray-600 dark:text-gray-400 font-medium text-left">{{ $t('climbs.started') }}</th>
					<th class="text-sm text-gray-600 dark:text-gray-400 font-medium text-center">{{ $t('climbs.rate') }}</th>
					<th class="text-sm text-gray-600 dark:text-gray-400 font-medium text-center">{{$t('climbs.start-alt')}}</th>
					<th class="text-sm text-gray-600 dark:text-gray-400 font-medium text-center">{{ $t('climbs.end-alt') }}</th>
				</tr>
				<tr v-for="climb in climbs" 
				:key="climb.start_ts"
				@click="clickClimb(climb)"
				class="hover:bg-gray-300 dark:hover:bg-gray-700 cursor-pointer"
				:class="[isSelectedThermal(climb) ? 'dark:bg-gray-600 bg-gray-400' : '']"
				>
				<!-- @click="" -->
					<td class="px-1 rounded-l-md">
 						<RocketLaunchIcon class="size-4" v-if="climb.launch"></RocketLaunchIcon>
						<ArrowPathIcon class="size-4" v-if="!climb.launch"></ArrowPathIcon>
					</td>
					<td class="text-sm">
						{{formatTimeFromJSDate(apiDateToJS(climb.start_ts), true, false, point.timezone, settings.timeUnits, settings.timezoneUnits)}}
					</td>
					<td class="text-center">
						{{renderClimbRate(climb)}}
					</td>
					<td class="px-1 text-sm text-center">
						{{renderHeight(climb.start_alt, settings.altitudeUnits)}}
					</td>
					<td class="text-sm text-center rounded-r-md">
						{{renderHeight(climb.max_alt, settings.altitudeUnits)}}
					</td>
				</tr>
			</table>
		</div>

	</div>


<!-----------  Location  ---------- -->
	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300">

	<div class="flex flex-col ml-2 gap-2" v-if="point">

		<div class="flex flex-wrap gap-4">

			<button 
				v-on:click="showLocation = !showLocation"
				class="text-sm text-left flex gap-1 items-center flex-grow">
				<ChevronRightIcon v-if="!showLocation" class="size-4"></ChevronRightIcon>
				<ChevronDownIcon v-if="showLocation" class="size-4"></ChevronDownIcon>
				<MapPinIcon class="size-4" />

				{{ $t('selected.location') }}

			</button>

			<div class="flex flex-wrap gap-2 items-center">
					{{point.lat}},
					{{point.long}}
			</div>

		</div>
	</div>

	<div v-if="showLocation" class="ml-2 flex flex-col gap-2">



		<div v-if="showLocation" class="flex gap-4 items-center">

			<div class="flex gap-2 items-center">
				<a :href="googleLink()" class="flex gap-1 items-center cursor-pointer">
					<LinkIcon class="size-5" /> <span class="link">Google Maps</span>
				</a>

				<DocumentDuplicateIcon v-if="!copiedGoogleLink" class="cursor-pointer size-4 text-gray-400" image="copy" v-on:click="copyGoogle(googleLink());" alt="copy to clipboard"></DocumentDuplicateIcon>
				<CheckIcon v-if="copiedGoogleLink" class="cursor-pointer size-4 text-green-600" image="copy"></CheckIcon>
			</div>


			<div class="flex gap-2 items-center">
				<a :href="appleLink()" class="flex gap-1 items-center cursor-pointer">
					<LinkIcon class="size-5" />  <span class="link">Apple Maps</span>
				</a>

				<DocumentDuplicateIcon v-if="!copiedAppleLink" class="cursor-pointer size-4 text-gray-400" image="copy" v-on:click="copyApple(appleLink());" alt="copy to clipboard"></DocumentDuplicateIcon>
				<CheckIcon v-if="copiedAppleLink" class="cursor-pointer size-4 text-green-600" image="copy"></CheckIcon>
			</div>

		</div>


	</div>





<!-----------  Source  ---------- -->

	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300">

	<div class="flex flex-col ml-2 gap-2 ">

		<div class="flex flex-wrap gap-4 items-center">
			<button
				v-on:click="settings.showSource = !settings.showSource"
				class="text-sm text-left flex gap-1 items-center flex-grow">
				<ChevronRightIcon v-if="!settings.showSource"  class="size-4"></ChevronRightIcon>
				<ChevronDownIcon v-if="settings.showSource"  class="size-4"></ChevronDownIcon>
				<ArrowRightEndOnRectangleIcon class="size-4" />
				{{ $t('selected.source') }}
			</button>

			<div class="whitespace-nowrap flex items-center gap-2">
				<div v-if="sources.getSource(point.source_id)?.icon" class="dark:bg-white p-1 rounded-full">
					<img :src="sources.getSource(point.source_id)?.icon" alt="Icon" class="size-5">
				</div>

				<div v-if="!sources.getSource(point.source_id)?.icon" class="dark:bg-white p-1 rounded-full">
					<div class="size-5"></div>
				</div>

				{{sources.getSource(point.source_id)?.name}}

			</div>



		</div>


		<div class="flex flex-col gap-2 " v-if="settings.showSource">
			
			<div class="whitespace-nowrap flex flex-col gap-2  flex-wrap">

				<div class="flex items-center gap-2">
					<div class="infobox-label">ID</div>

					<div class="flex gap-1 items-center">
						<div class="overflow-clip overflow-ellipsis">{{point.tracker_uid}}</div>
						<DocumentDuplicateIcon v-if="!copied" class="cursor-pointer size-4 text-gray-400" image="copy" v-on:click="clickIDcopy(point.tracker_uid); copied=true" alt="copy to clipboard"></DocumentDuplicateIcon>
						<CheckIcon v-if="copied" class="cursor-pointer size-4 text-green-600" image="copy" v-on:click="clickIDcopy(point.tracker_uid);" alt="copy to clipboard"></CheckIcon>
					</div>
				</div>

				<div class="flex items-center gap-2">
					<div class="infobox-label">Receiver</div>
					<div class="overflow-hidden overflow-ellipsis" 
					v-if="point?.receiver_name">
						{{point.receiver_name}}
					</div>
				</div>
			</div>

		</div>


		<div class="whitespace-nowrap flex flex-col  gap-1" v-if="settings.showSource">
			<div class="flex gap-2 items-center">
				<span class="infobox-label">
					{{ $t('selected.sources-in-last-8-weeks') }}
				</span>
				<LoadingHorizontal v-if="sourceCountsLoading" />
			</div>
				
			<div v-if="sourceCounts" class="flex flex-col gap-1">

				<table class="table-auto">
					<tr v-for="sourceCount in sourceCounts" :key="sourceCount.src" class="whitespace-nowrap">
						<td class="pr-1 whitespace-nowrap w-8">
									
							<div v-if="sources.getSource(sourceCount.src)?.icon" class="dark:bg-white p-1 rounded-full">
								<img :src="sources.getSource(sourceCount.src)?.icon" alt="Icon" class="size-5">
							</div>
						</td>
						<td class="pr-1">
							{{sourceCount.source?.name}} 
						</td>
						<td class="pr-1 text-center">
							{{sourceCount.src_count}}
						</td>
						<td class="text-right">
							{{ renderTimeAgo(apiDateToJS(sourceCount.last_ts), app.currentTime) }} ago
						</td>
					</tr>
				</table>
			</div>
		</div>


	</div>





<!-----------  Aircraft Details  ---------- -->

	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300" v-if="point?.rego">

	<div class="flex flex-col ml-2 gap-2 " v-if="point?.rego">

		<div class="flex flex-wrap gap-4 items-center">
			<button
				v-on:click="settings.showInfo = !settings.showInfo"
				class="text-sm text-left flex gap-1 items-center flex-grow">
				<ChevronRightIcon v-if="!settings.showInfo"  class="size-4"></ChevronRightIcon>
				<ChevronDownIcon v-if="settings.showInfo"  class="size-4"></ChevronDownIcon>
				<PaperAirplaneIcon class="size-4" />
				{{ $t('selected.aircraft-details') }}

			</button>

			<div v-if="point.rego" class="whitespace-nowrap ">
				{{point.rego}}
			</div>
		</div>


		<div  v-if="settings.showInfo && point.rego" class="whitespace-nowrap flex gap-2 items-baseline">
			<div class="infobox-label">Contest Rego</div>
			<div v-if="point.comp" class="whitespace-nowrap">
				{{point.comp}}
			</div>
		</div>

		<AircraftDetails v-if="settings.showInfo && point.rego" :point="point" />

		<div v-if="settings.showInfo && point.rego">
			<a v-if="point.rego"
			:href="'/aircraft/' + point.rego"  
			class="infobox infobox-clickable flex items-center gap-1 w-fit">

				<PencilIcon class="size-4" />
				{{$t('legend.edit-aircraft')}}
			</a>
		</div>

	</div>

<!-----------  Debug  ---------- -->

	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300 ">

	<div class="flex flex-col ml-2 gap-2" v-if="point">

		<button 
			v-on:click="showDebug = !showDebug"
			class="text-sm text-left flex gap-1 items-center">
			<ChevronRightIcon v-if="!showDebug"  class="size-4"></ChevronRightIcon>
			<ChevronDownIcon v-if="showDebug"  class="size-4"></ChevronDownIcon>
			<BugAntIcon class="size-4" />
			{{ $t('selected.debug') }}

		</button>


		<div v-if="showDebug" class="flex flex-wrap gap-2 items-center">
			<div v-if="point?.alt_standard" class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">QNH Alt</div>
				<div class="flex-1 whitespace-nowrap">{{renderHeight(point.alt_standard, settings.altitudeUnits)}}</div>
			</div>

			<div v-if="point?.pressure" class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">QNH</div>
				<div class="flex-1 whitespace-nowrap">{{point.pressure}}</div>
			</div>
		</div>


		<div v-if="showDebug" class="flex flex-wrap gap-2 items-center">
			<div v-if="point"  class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">Key</div>
				<div class="flex-1 whitespace-nowrap">{{point.key}}</div>
			</div>

			<div v-if="point?.tracker_uid" class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">TrackerUID</div>
				<div class="flex-1 whitespace-nowrap">{{point.tracker_uid}}</div>
			</div>
		</div>


		<div v-if="showDebug" class="flex flex-wrap gap-2 items-center">

			<div v-if="point"  class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">Timestamp</div>
				<div class="flex-1 whitespace-nowrap">{{point.stamp}}</div>
			</div>
		</div>


		<div v-if="showDebug" class="flex flex-wrap gap-2 items-center">
			<div class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">TrackerID</div>
				<div class="flex-1 whitespace-nowrap" v-if="point?.tracker_id" >{{point.tracker_id}}</div>
			</div>

			<div class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				<div class="infobox-label flex-1">TargetID</div>
				<div class="flex-1 whitespace-nowrap" v-if="point.target_id" >{{point.target_id}}</div>
			</div>
		</div>


		<div v-if="showDebug" class="flex flex-wrap gap-2 items-center">
			<div class="whitespace-nowrap-2 flex-1 flex gap-2 items-center">
				
			</div>
		</div>


		<!-- <button
			@click="toggleFollow(point.key)" 
			:title="$t('selected.follow')" 
			class="whitespace-nowrap flex items-center justify-center gap-1"
			:class="[settings.followSelected ? 'infobox-selected ' : '']">
			<ViewfinderCircleIcon class="size-4"
			 />
			{{$t('selected.follow')}}
		</button> -->

	</div>


<!-----------  Buttons  ---------- -->

	<hr class="ml-2 border-t border-b dark:border-b-gray-700 dark:border-t-black border-b-white border-t-gray-300">

		<div class="flex flex-wrap justify-center ml-2 gap-2 py-4 " v-if="point" >

			<button :title="$t('download.download')" class="infobox infobox-clickable nowrap flex items-center justify-center gap-1" @click="app.show='download-menu'">
				<DownloadIcon class="size-4"></DownloadIcon>

				{{$t('download.download')}}

			</button>

			<button :title="$t('legend.toggle-share')" class="infobox infobox-clickable nowrap flex items-center justify-center gap-1" @click="app.show='share-menu'">
				<ArrowUpOnSquareIcon class="size-4" />
				{{$t('selected.share')}}
			</button>

			<button :title="$t('alerts.alerts')" class="infobox infobox-clickable nowrap flex items-center justify-center gap-1" @click="app.show='alerts-menu'">
				<BellAlertIcon class="size-4" />
				{{$t('alerts.alerts')}}
				<div class="bg-white bg-opacity-50 text-black rounded-full w-5 text-sm" v-if="app.alerts.length">{{app.alerts.length}}</div>
			</button>

			<div class="flex flex-wrap justify-center gap-1 sm:gap-2">

				<button
					:title="$t('selected.add-to-group')" 
					v-if="app.groupLoaded && !groups.isInGroup(point.key)" 
					@click="groups.toggleGroup(point)"
					class="infobox infobox-clickable whitespace-nowrap flex items-center justify-center gap-1">
					<UserPlusIcon class="size-4" />
					{{$t('selected.add-to-group')}}
				</button>


				<button 
					:title="$t('selected.remove-from-group')" 
					v-if="app.groupLoaded && groups.isInGroup(point.key)" 
					@click="groups.toggleGroup(point)"
					class="infobox infobox-clickable whitespace-nowrap flex items-center justify-center gap-1">
					<UserMinusIcon class="size-4" />
					{{$t('selected.remove-from-group')}}
				</button>
			</div>

		</div>


	</div>

</div>







		</div>
	</div>


</div></template>